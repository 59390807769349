import React, { useState } from "react";
import axios from "axios";
import { Card, CardBody, Col, Row, Container, Alert } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FormTransactionPassword = () => {
  document.title = "Transaction Password | Human rights CWA";

  const [formData, setFormData] = useState({
    transactionPassword: "",
  
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://mlm-backend-9fnq.onrender.com/api/user/add-transaction-password",
        { transactionPassword: formData.transactionPassword }, // Send data as JSON
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Set content type to JSON
          },
        }
      );
  
      setSuccessMessage("Transaction password set successfully");
      setErrorMessage("");
    } catch (error) {
      console.error("Error updating profile:", error);
      setSuccessMessage("");
      setErrorMessage("Error updating profile");
    }
  };
  
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Profile Management" breadcrumbItem="Transaction Password" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <label htmlFor="transactionPassword" className="col-md-2 col-form-label">
                      Transaction Password
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          id="transactionPassword"
                          name="transactionPassword"
                          value={formData.transactionPassword}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>
           
                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    <button type="submit" className="btn btn-primary">Submit</button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormTransactionPassword;
