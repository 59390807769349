// Home.jsx
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import banner1 from './../assets/images/bannernew1.png'; 
import banner2 from './../assets/images/bannernew2.png'; 
import banner3 from './../assets/images/bannernew3.png'; 
import banner4 from './../assets/images/banner4.jpeg'; 
// import banner4 from './../assets/images/bannernew4.png'; 
// import image1 from './../assets/images/galleryImages/WhatsApp Image 2024-09-20 at 4.39.42 PM (1).jpeg'; 
// import image2 from './../assets/images/galleryImages/WhatsApp Image 2024-09-20 at 4.40.03 PM (1).jpeg'; 
import image3 from './../assets/images/galleryImages/WhatsApp Image 2024-09-20 at 4.40.12 PM (1).jpeg'; 
import image4 from './../assets/images/galleryImages/WhatsApp Image 2024-09-20 at 4.40.16 PM.jpeg'; 
import image5 from './../assets/images/bannernew3.png'; 
import image6 from './../assets/images/bannernew3.png'; 
import hrcwa from './../assets/images/about1.png'; 
import NavBar from './NavBar';
import logo from './../assets/images/about1.png';
import about from './../assets/images/about.png';
import aboutnew from './../assets/images/newabout.png';
import aboutV from './../assets/images/aboutV.png';
import './Home.css'; 

const images = [banner1, banner2, ,banner4];
const imagesGallery = [ image3,image4];

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Display one slide at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: true, // Adjust height based on current slide
};


  return (
    <div className="home-container container-fluid p-0 " style={{width:"100%"}}>
      <NavBar whiteBg={true} />
      <div className="banner-container m-0 p-0">
        <Slider {...settings} >
          {images.map((image, index) => (
            <div key={index} className="slide" >
              <img src={image} alt={`Banner ${index + 1}`} className="banner-image w-100" />
            </div>
          ))}
        </Slider>
      </div>

      <header className="py-4 bg-white text-dark">
        <div className="container">
          <div className="row align-items-center">
            {/* <div className="col-12">
              <img src={logo} alt="About Us" className="img-fluid rounded" />
            </div> */}
            <div className="col-12">
           
              <div className="row mt-4">
                <div className="col-md-4 order-md-1 order-2 text-center">
                  <img src={about} alt="About Us" className="img-fluid rounded" />
                </div>
                <div className="col-md-8 text-md-start order-md-2 order-1 ">
                <h3 className='text-danger mt-md-5'>About Us</h3>
                  <p className='text-justify'>
                  In a globalizing world where millions of individuals and communities face discrimination and violence, it is essential to have a shared value system that calls on governments, institutions and individuals to respect the fundamental right of all anchorpeople Right are the closet thing the world has to such a system-and the Universal Declaration of Human Rights represents its basic underpinning notion that all human beings have the right to live with dignity,equality and justice. Human Rights are intrinsic and belong to everyone. There are no exceptions based on religion,caste,gender,class,sexuality,geographic location or any other factor. There are interconnected,indivisible and intersection. Human Rights are part of our every day lives,and each one of us is responsible for their protection and promotion. Human Rights are fundamental rights expected for every human.”I call on States to honor their obligation to protect human rights every day of the year. I call on people to hold their governments to account. “
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-3 mb-3">
          <h3 className='text-danger text-center'>Gallery</h3>
          <div className="row gallery mt-4">
            {imagesGallery.map((src, index) => (
              <div key={index} className="col-6 col-md-4 mb-3">
                <img src={src} alt={`Gallery item ${index}`} className="img-fluid" />
              </div>
            ))}
          </div>
        </div>

        <div className="contact-container">
        <h3 className='text-danger mt-0 mb-3'>Conatct</h3>
        <div className="contact-info-card" >
          <div className="contact-info-left" style={{textAlign:"start"}}>
            <h2 className="font-size-18 text-muted mt-2" >Contact Information</h2>
            <p><strong>Head Office:</strong> A21, Jawahar Nagar , Ratlam-457001 Madhya Pradesh,India</p>
            <p><strong>Contact Numbers:</strong></p>
            <ul>
              {/* <li>09450445150</li> */}
              <li> (+91) 9039551697</li>
              <li>(+91) 9039157936</li>
              {/* <li>Ramnagar R.K Jhaa (+91) 7080464601</li>
              <li>Saidupur A.K Diwvedi (+91) 9451000135</li>
              <li>Mughalsarai M.L Chaudhari (+91) 9473632474</li>
              <li>Varanasi Dr.V Singh (+91) 9454311076</li> */}
            </ul>
          </div>
          <div className="contact-info-right" style={{textAlign:"start"}}>
            <p><strong className="font-size-18 text-muted text-center mt-2">Email Ids:</strong></p>
            <ul>
              <li>info@humanrightcwa.co.in</li>
             
              <li>humanrightsgsmp@gmail.com</li>
            </ul>
         
          </div>
        </div>
      
      </div>
      </header>
    </div>
  );
};

export default Home;
