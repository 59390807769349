import axios from "axios";
import { API_ENDPOINTS } from "../API/authAPI";

export const registerUser = async (userData) => {
    try {
        const response = await axios.post(API_ENDPOINTS.REGISTER, userData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error in registerUser function:", error); // Log error for debugging
        throw error; // Re-throw the error to be caught in the component
    }
};

export const loginUser = async (userData) => {
    try {
        const response = await axios.post(API_ENDPOINTS.LOGIN, userData, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
       
        return response;
    } catch (error) {
        console.error("Error in registerUser function:", error); // Log error for debugging
        throw error; // Re-throw the error to be caught in the component
    }
};

