import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import logo from './../assets/images/favicon.png';

const TopBar = () => {
  return (
    <header className="bg-dark py-2">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          {/* Logo Section */}
          <Link to="/" className="d-flex align-items-center text-decoration-none">
            <img src={logo} alt="Logo" style={{ height: '50px' }} />
            {/* <h1 className="h5 mb-0 ms-2 text-dark">MyLogo</h1> */}
          </Link>

          {/* Contact Information */}
          <span className="d-none d-md-block text-white">Email: info@example.com</span>

          {/* Mobile Menu Button */}
          <button
            className="navbar-toggler d-md-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        {/* Mobile Navigation */}
        <div className="collapse d-md-none mt-3" id="navbarNav">
          <nav className="d-flex flex-column">
            <Link to="/" className="nav-link text-dark">Home</Link>
            <Link to="/about" className="nav-link text-dark">About</Link>
            <Link to="/services" className="nav-link text-dark">Services</Link>
            <Link to="/contact" className="nav-link text-dark">Contact</Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default TopBar;
