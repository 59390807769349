import React, { useState } from "react";
import axios from "axios";
import { Card, CardBody, Col, Row, Container, Alert } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FormUpdateKYC = () => {
  document.title = "Update KYC |  Human rights CWA";

  const [formData, setFormData] = useState({
    panCardNo: "",
    adharCardNo: "",
    pancardImage: null,
    adharFrontImage: null,
    adharBackImage: null,
    bankAccountName: "",
    bankAccountNo: "",
    ifsc: "",
    bankName: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData({
        ...formData,
        [name]: files[0],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      const formDataToSend = new FormData();

      // Append text fields
      Object.keys(formData).forEach((key) => {
        if (key !== "pancardImage" && key !== "adharFrontImage" && key !== "adharBackImage") {
          formDataToSend.append(key, formData[key]);
        }
      });

      // Append files only if they exist
      if (formData.pancardImage) formDataToSend.append("pancardImage", formData.pancardImage);
      if (formData.adharFrontImage) formDataToSend.append("adharFrontImage", formData.adharFrontImage);
      if (formData.adharBackImage) formDataToSend.append("adharBackImage", formData.adharBackImage);

      await axios.post("https://mlm-backend-9fnq.onrender.com/api/user/kyc", formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setSuccessMessage("Profile updated successfully");
      setErrorMessage("");
    } catch (error) {
      console.error("Error updating profile:", error);
      setSuccessMessage("");
      setErrorMessage("Error updating profile");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Profile Management" breadcrumbItem="Update KYC" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <label htmlFor="panCardNo" className="col-md-2 col-form-label">
                        Pan Card No.
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          id="panCardNo"
                          name="panCardNo"
                          value={formData.panCardNo}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="adharCardNo" className="col-md-2 col-form-label">
                        Aadhar Card No.
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          id="adharCardNo"
                          name="adharCardNo"
                          value={formData.adharCardNo}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="pancardImage" className="col-md-2 col-form-label">
                        Pancard Image
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="file"
                          id="pancardImage"
                          name="pancardImage"
                          onChange={handleFileChange}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="adharFrontImage" className="col-md-2 col-form-label">
                        Aadhar Front Image
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="file"
                          id="adharFrontImage"
                          name="adharFrontImage"
                          onChange={handleFileChange}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="adharBackImage" className="col-md-2 col-form-label">
                        Aadhar Back Image
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="file"
                          id="adharBackImage"
                          name="adharBackImage"
                          onChange={handleFileChange}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="bankAccountName" className="col-md-2 col-form-label">
                        Bank Account Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          id="bankAccountName"
                          name="bankAccountName"
                          value={formData.bankAccountName}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="bankAccountNo" className="col-md-2 col-form-label">
                        Bank Account No.
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          id="bankAccountNo"
                          name="bankAccountNo"
                          value={formData.bankAccountNo}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="ifsc" className="col-md-2 col-form-label">
                        IFSC
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          id="ifsc"
                          name="ifsc"
                          value={formData.ifsc}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label htmlFor="bankName" className="col-md-2 col-form-label">
                        Bank Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          id="bankName"
                          name="bankName"
                          value={formData.bankName}
                          onChange={handleChange}
                        />
                      </div>
                    </Row>
                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    <button type="submit" className="btn btn-primary">Update KYC</button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormUpdateKYC;
