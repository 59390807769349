import React from 'react';
import './Contact.css'; // Make sure to create this CSS file or add styles directly
import NavBar from './NavBar';

const Contact = () => {
  return (
    <div>
      <NavBar />
      <div className="contact-container" style={{marginTop:"50px"}}>
        <div className="contact-info-card  margin-custom">
          <div className="contact-info-left">
            <h2 className="font-size-18 text-muted text-center mt-2">Contact Information</h2>
            <p><strong>Head Office:</strong> A21, Jawahar Nagar , Ratlam-457001 Madhya Pradesh,India</p>
            <p><strong>Contact Numbers:</strong></p>
            <ul>
              {/* <li>09450445150</li> */}
              <li> (+91) 9039551697</li>
              <li>(+91) 9039157936</li>
              {/* <li>Ramnagar R.K Jhaa (+91) 7080464601</li>
              <li>Saidupur A.K Diwvedi (+91) 9451000135</li>
              <li>Mughalsarai M.L Chaudhari (+91) 9473632474</li>
              <li>Varanasi Dr.V Singh (+91) 9454311076</li> */}
            </ul>
          </div>
          <div className="contact-info-right">
            <p><strong className="font-size-18 text-muted text-center mt-2">Email Ids:</strong></p>
            <ul>
              <li>info@humanrightcwa.co.in</li>
             
              <li>humanrightsgsmp@gmail.com</li>
            </ul>
         
          </div>
        </div>
        <div className="map-container">
          <h2 className="font-size-18 text-muted text-center mt-2">Our Location</h2>
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.870451481251!2d-122.4012675846809!3d37.78985877975866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085808a2d9dfd47%3A0x5ad9c12d5d6a0f3!2sSalesforce%20Tower!5e0!3m2!1sen!2sus!4v1654587026395!5m2!1sen!2sus"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
