import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './../assets/images/about1.png';
import about from './../assets/images/about.png';
import aboutnew from './../assets/images/newabout.png';
import aboutV from './../assets/images/aboutV.png';
import NavBar from './NavBar';
import Footer from './Footer';

const About = () => {
  return (
    <div className=''>
        <NavBar/>
      
    <header className="bg-white py-4 text-white px-md-5  px-2 margin-custom " style={{marginTop:"80px"}}>
      <div className="container">
      {/* <div className="row align-items-center col-md-12 ">
            <img 
              src={logo} 
              alt="About Us" 
              className="img-fluid rounded" 
              style={{ maxWidth: '100%', height: 'auto' }} 
            />
            
          </div> */}
          <br></br>
          <br></br>
        <div className="row">
          {/* Image Section */}
          <div className="col-lg-4 col-12 text-center  order-md-1 order-2">
            <img 
              src={about} 
              alt="About Us" 
              className="img-fluid rounded" 
              style={{ maxWidth: '100%', height: 'auto' }} 
            />
            
          </div>

          {/* About Us Content */}
          <div className="col-lg-8 col-12  order-md-2 order-1">
            <h1 className="font-size-18 text-muted text-lg-start text-center">About Us</h1>
            <p className='text-dark text-justify'>
            In a globalizing world where millions of individuals and communities face discrimination and violence, it is essential to have a shared value system that calls on governments, institutions and individuals to respect the fundamental right of all anchorpeople Right are the closet thing the world has to such a system-and the Universal Declaration of Human Rights represents its basic underpinning notion that all human beings have the right to live with dignity,equality and justice. Human Rights are intrinsic and belong to everyone. There are no exceptions based on religion,caste,gender,class,sexuality,geographic location or any other factor.
There are interconnected,indivisible and intersection. Human Rights are part of our every day lives,and each one of us is responsible for their protection and promotion.

Human Rights are fundamental rights expected for every human.”I call on States to honor their obligation to protect human rights every day of the year. I call on people to hold their governments to account. “
            </p>
            {/* Add more content as needed */}
          </div>
      
        </div>
      </div>
      {/* <div style={{marginLeft:"15%" ,fontFamily:"bold",fontSize:"20px"}}>
         <p className='text-danger'>We are providing Registration facility.Which person want to join Human Right C.W.A contact to our chairman Mr.Yogendra Singh.</p>
         </div> */}
         <div className="row align-items-center col-md-12 ">
            <img 
              src={aboutnew} 
              alt="About Us" 
              className="img-fluid rounded" 
              style={{ maxWidth: '100%', height: 'auto' }} 
            />
            
          </div>
          {/* <br></br>
          <br></br>
          <div className="row align-items-center col-md-10 ">
            <img 
              src={aboutV} 
              alt="About Us" 
              className="img-fluid rounded" 
              style={{ maxWidth: '100%', height: 'auto',marginLeft:"10%" }} 
            />
            
          </div>
          <br></br>
          <div><p className='text-dark' style={{marginLeft:"30%"}}>Design & Developed by American-Soft © Copyright 2016, Human Right C.W.A.</p></div> */}
    </header>
  
    </div>
  );
};

export default About;
