import React from 'react';
import './Gallery.css'; // Make sure to create this CSS file or add styles directly

// Import individual images if necessary
import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';
import NavBar from './NavBar';

// Dynamically load all images from the galleryImages folder
const importAll = (r) => r.keys().map(r);
const galleryImages = importAll(require.context('../assets/images/galleryImages/', false, /\.(png|jpe?g|svg)$/));

const images = [

  banner2,
  ...galleryImages // Spread the dynamically loaded images
];

const Gallery = () => {
  return (
    <div className=''>
      <NavBar/>
      <div className='mt-md-2 mt-5 pt-md-0 pt-5'>
        <h3 className="font-size-18 text-muted text-center" style={{ textAlign: 'center', marginTop: "10%", marginBottom: "4%", color: 'red' }}>
          Gallery
        </h3>
      
        <div className="gallery">
          {images.map((src, index) => (
            <div key={index} className="gallery-item">
              <img src={src} alt={`Gallery item ${index}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
