import React, { useEffect } from 'react';
import NavBar from './NavBar';

const TermsCondition = () => {
  // Scroll to top when the component is mounted
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <NavBar />
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', marginTop: '7%' }}>
        <h3 className="font-size-18 text-muted text-center mt-md-2 mt-5 pt-md-0 pt-5 text-justify" style={{ textAlign: 'center', marginBottom: '20px', color: 'red' }}>
          Terms & Conditions
        </h3>
        <p>
          <strong>Human Right CWA</strong> - This payment will be taken from any member in the form of a donation, and it will be used in old age ashrams, cow service, and any good work in the interest of human beings. This money will not be used in unethical or wrong work. This consent will not be taken.
        </p>
        <p>
          President of the organization: <strong>Yogendra Singh Ji Yogi</strong>
        </p>
        <p>
          National General Secretary: It has been decided with the consent of General Secretary <strong>Narendra Singh Chauhan</strong> that no claim can be made against this organization because if anyone makes any claim, it will be a violation of human rights. This organization has been working for human rights for the last 15 years. It has done many things to protect humans and it is audited annually. Any work within the law will be done for the protection of human beings as per section 19A to 25A of the Indian Constitution. Any moral or wrong work will not be done in the service.
        </p>
        <p>
          <strong>Yours sincerely,</strong><br />
          National General Secretary<br />
          <strong>Narendra Singh Chauhan</strong><br />
          Human Rights CWA
        </p>
        <p>
          <strong>Note:</strong> Any person, after reading all the terms and conditions, is doing the transaction as per his own wish and discretion. He cannot put any kind of pressure on the organization, nor will the organization put pressure on him. If any member commits any wrongdoing after joining or commits criminal acts against the organization, the organization will not require anyone's consent to remove or separate him without any pressure and take action against him. After giving notice, he will be immediately removed and dismissed from membership.
        </p>
        <p>
          Nor can any kind of pressure be put on the member organization to do proper work. We are doing this work as a service.
        </p>

        {/* Hindi Content */}
        <h3 className="font-size-18 text-muted text-center mt-md-2 mt-5 pt-md-0 pt-5 text-justify" style={{ textAlign: 'center', marginBottom: '20px', color: 'red' }}>
          नियम और शर्तें
        </h3>
        <p>
          <strong>ह्यूमन राइट सी डब्लू ऐ</strong> - यह पेमेंट डोनेशन के रूप में किसी भी मेंबर से लिया जाएगा तथा इसका उपयोग वृद्ध आश्रम गौ सेवा एवं मानव के हित में किसी भी अच्छे कार्य के रूप में लगाया जाएगा। अनैतिक कार्य के रूप में इस रुपए को या गलत कार्य में नहीं लिया जाएगा। यह सहमति संस्था के अध्यक्ष वह राष्ट्रीय महासचिव की सहमति से निर्णय लिया गया है। कोई भी क्लेम इस संस्था के ऊपर नहीं किया जा सकता, इसलिए कि अगर कोई भी क्लेम करता है तो यह मानव अधिकारों का उल्लंघन होगा। यह संस्था मानव अधिकार के लिए कार्य करती है, विगत 15 सालों से मानव की रक्षा के लिए अनेक कार्य कर चुकी है एवं इसका वार्षिक ऑडिट होता है। कोई भी कार्य कानून के अंदर वह भारतीय संविधान की धारा 19A से 25A तक मानव की रक्षा के लिए कार्य करेंगे। अनैतिक या गलत कार्य किसी भी प्रकार का नहीं किया जाएगा।
        </p>
        <p>
          सेवा में,<br />
          राष्ट्रीय महासचिव<br />
          <strong>नरेंद्र सिंह चौहान</strong><br />
          ह्यूमन राइट सी डब्लू ऐ
        </p>
        <p>
          <strong>नोट:</strong> कोई भी व्यक्ति सारी शर्तों को पढ़कर उसकी मर्जी तथा खुद के विवेक से लेन-देन कर रहा है। संस्था के ऊपर किसी भी प्रकार का कोई दबाव नहीं डाल सकता है, ना ही संस्था का दबाव उसके ऊपर रहेगा। यदि कोई भी मेंबर जुड़ने के बाद गलत कार्य संस्था के साथ करता है या आपराधिक कार्य करता है तो संस्था बिना दबाव के किसी भी प्रकार से उसको हटाने और उसके ऊपर कार्रवाई करने के लिए किसी की सहमति की जरूरत नहीं होगी। एक नोटिस देने के बाद उसको तुरंत हटा दिया जाएगा एवं सदस्यता से बर्खास्त किया जाएगा। ना ही किसी प्रकार का दबाव मेंबर संस्था के ऊपर डाल सकता है उचित कार्य करने के लिए। हमने यह कार्य सेवा के रूप में कर रहे हैं।
        </p>
      </div>
    </div>
  );
};

export default TermsCondition;
