import React from 'react';
import './Footer.css'; // Make sure to create this CSS file or add styles directly

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <h1>Human Rights CWA</h1>
          </div>
          <div className="footer-links">
            <ul>
              <li><a href="#">Home</a></li>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Services</a></li>
              <li><a href="#">Contact</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>
          </div>
          <div className="footer-social">
            <a href="#" className="social-icon">Facebook</a>
            <a href="#" className="social-icon">Twitter</a>
            <a href="#" className="social-icon">LinkedIn</a>
            <a href="#" className="social-icon">Instagram</a>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 Human Rights CWA. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
