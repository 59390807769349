import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";

const WithdrawRequestTable = () => {
  document.title = "Withdraw | Human rights CWA";

  const [withdrawRequests, setWithdrawRequests] = useState([]);

  useEffect(() => {
    // Fetch the withdraw request history when the component mounts
    const fetchWithdrawRequests = async () => {
      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem("token");

        // Make the API request with the Authorization header
        const response = await axios.get(
          "https://mlm-backend-9fnq.onrender.com/api/user/get-withdraw-request",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update the state with the fetched withdraw requests
        if (response && response) {
          setWithdrawRequests(response); // Adjust according to your API response structure
        }
      } catch (error) {
        console.error("Error fetching withdraw requests:", error);
      }
    };

    fetchWithdrawRequests();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Withdraw" breadcrumbItem="Withdraw History" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Withdraw History</h4>
                  <p className="card-title-desc">
                    Below is the history of all your withdraw requests.
                  </p>

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          
                          <th>Amount</th>
                          <th>Status</th>
                          <th>Request Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {withdrawRequests.length > 0 ? (
                          withdrawRequests.map((request, index) => (
                            <tr key={request._id}>
                              <th scope="row">{index + 1}</th>
                            
                              <td>{request.amount}</td>
                              <td style={{
  color: request.status === 'pending' ? 'blue' :
         request.status === 'approved' ? 'green' :
         request.status === 'rejected' ? 'red' : 'black'
}}>
  {request.status}
</td>


<td>{new Date(request.createdAt).toLocaleString()}</td>

                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No withdraw requests found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WithdrawRequestTable;
