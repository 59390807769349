import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Button, Input } from "reactstrap";
import RadialChart1 from "./userpanelChart1";
import RadialChart2 from "./userpanelChart2";
import RadialChart3 from "./userpanelChart3";
import axios from "axios";

const UserPanel = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [leftUsers, setLeftUsers] = useState(0);
  const [rightUsers, setRightUsers] = useState(0);
  const [commission, setCommission] = useState("");
  const [leftReferralLink, setLeftReferralLink] = useState("");
  const [rightReferralLink, setRightReferralLink] = useState("");
  const [packageDetails, setPackageDetails] = useState({
    name: "",
    price: 0,
  });
  const [withdrawalRequests, setWithdrawalRequests] = useState({
    pending: 0,
    approved: 0,
    rejected: 0,
  });

  useEffect(() => {
    const fetchUsersData = async () => {
      try {
        const token = localStorage.getItem("token"); // Retrieve token from local storage

        // Fetch user data
        const userResponse = await axios.get("https://mlm-backend-9fnq.onrender.com/api/user/get-teams", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const userData = userResponse;
        setCommission(userData.userProfile?.commission || "N/A");
        setLeftReferralLink(userData.userProfile?.referralLinks?.leftLink || "");
        setRightReferralLink(userData.userProfile?.referralLinks?.rightLink || "");

       // Helper function to flatten nested referrals
const flattenReferrals = (referrals) => {
  let flatList = [];

  referrals.forEach((referral) => {
    flatList.push(referral);
    if (referral.referrals && referral.referrals.length > 0) {
      flatList = [...flatList, ...flattenReferrals(referral.referrals)];
    }
  });

  return flatList;
};

if (userData.success) {
  // Flatten the nested referrals
  const allUsers = flattenReferrals(userData.referrals);

  const total = allUsers.length;
  const left = allUsers.filter((user) => user.position === "left").length;
  const right = allUsers.filter((user) => user.position === "right").length;

  setTotalUsers(total);
  setLeftUsers(left);
  setRightUsers(right);
}


        // Fetch package details
        const packageResponse = await axios.get("https://mlm-backend-9fnq.onrender.com/api/user/get-select-packages", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPackageDetails({
          name: packageResponse.package.name || "N/A",
          price: packageResponse.package.price || 0,
        });

        // Fetch withdrawal requests
        const withdrawalResponse = await axios.get("https://mlm-backend-9fnq.onrender.com/api/user/get-withdraw-request", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const withdrawalData = withdrawalResponse;
        setWithdrawalRequests({
          pending: withdrawalData.filter((req) => req.status === "pending").length,
          approved: withdrawalData.filter((req) => req.status === "approved").length,
          rejected: withdrawalData.filter((req) => req.status === "rejected").length,
        });

      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchUsersData();
  }, []);

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      alert("Referral link copied!");
    });
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 series={[totalUsers]} />
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total Users</p>
                  <h5 className="mb-3">{totalUsers}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
            <div className="d-flex">
                                <div className="flex-shrink-0 me-3 align-self-center">
                                    <RadialChart2 series={[leftUsers]} color="#0ab39c" />
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Left Users</p>
                                    <h5 className="mb-3">{leftUsers}</h5>
                                </div>
                            </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
            <div className="d-flex text-muted">
                                <div className="flex-shrink-0 me-3 align-self-center">
                                    <RadialChart3 series={[rightUsers]} color="#ff5722" />
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="mb-1">Right Users</p>
                                    <h5 className="mb-3">{rightUsers}</h5>
                                </div>
                            </div>  
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-group-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Donation</p>
                  <h5 className="mb-3">{commission}</h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Referral Link Left */}
        <Col xl={6} sm={6}>
          <Card>
            <CardBody>
              <p className="mb-1">Left Referral Link</p>
              <Input type="text" value={leftReferralLink} readOnly />
              <Button color="primary" className="mt-2" onClick={() => copyToClipboard(leftReferralLink)}>
                Copy Left Link
              </Button>
            </CardBody>
          </Card>
        </Col>

        {/* Referral Link Right */}
        <Col xl={6} sm={8}>
          <Card>
            <CardBody>
              <p className="mb-1">Right Referral Link</p>
              <Input type="text" value={rightReferralLink} readOnly />
              <Button color="primary" className="mt-2" onClick={() => copyToClipboard(rightReferralLink)}>
                Copy Right Link
              </Button>
            </CardBody>
          </Card>
        </Col>

        {/* Package Details */}
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <p className="mb-1">Package Details</p>
              <h6 className="mb-1">{packageDetails.name}</h6>
              <h6 className="mb-1"> {packageDetails.price}</h6>
            </CardBody>
          </Card>
        </Col>

        {/* Withdrawal Request Statuses */}
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              {/* <p className="mb-1">Pending Requests</p>
              <h6 className="mb-1">{packageDetails.name}</h6>
              <h6 className="mb-3">{withdrawalRequests.pending}</h6> */}
                <p className="mb-1">Withdrawal</p>
              <h6 className="mb-1">Pending Requests</h6>
              <h6 className="mb-1">{withdrawalRequests.pending}</h6>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <p className="mb-1">Withdrawal</p>
              <h6 className="mb-1">Approved Requests</h6>
              <h6 className="mb-1">{withdrawalRequests.approved}</h6>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
            <p className="mb-1">Withdrawal</p>
              <h6 className="mb-1">Rejected Requests</h6>
              <h6 className="mb-1">{withdrawalRequests.rejected}</h6>
           
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserPanel;
