import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../../Services/auth"; // Adjust the import path as needed
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';
import NavBar from "../../Pages/NavBar";

const Register = () => {
    document.title = "Register | Human Rights CWA";

    const [registrationError, setRegistrationError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [agreedToTerms, setAgreedToTerms] = useState(false); // State for checkbox
    const navigate = useNavigate();

    // Extract referral data from URL
    const queryParams = new URLSearchParams(window.location.search);
    const referralId = queryParams.get("referralId");
    const username = queryParams.get("username");
    const position = queryParams.get("position");

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
            sponsorId: referralId || '',
            sponsorName: username || '',
            position: position || '',
            firstName: '',
            lastName: '',
            gender: '',
            country: '',
            mobileNumber: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email").required("Email is required"),
            password: Yup.string().required("Password is required").min(6, "Password should be at least 6 characters"),
            sponsorId: Yup.string().required("Sponsor ID is required"),
            sponsorName: Yup.string().required("Sponsor Name is required"),
            position: Yup.string().required("Position is required"),
            firstName: Yup.string().required("First Name is required"),
            lastName: Yup.string().required("Last Name is required"),
            gender: Yup.string().required("Gender is required"),
            country: Yup.string().required("Country is required"),
            mobileNumber: Yup.string().required("Mobile Number is required"),
        }),
        onSubmit: async (values) => {
            console.log("Form submitted with values:", values);
            try {
                const response = await registerUser(values);
                setSuccessMessage("User Registered Successfully!");
                setTimeout(() => {
                    navigate("/login");
                }, 2000);
                setRegistrationError("");
            } catch (error) {
                setSuccessMessage("");
                setRegistrationError(error.message || "An unexpected error occurred. Please try again.");
            }
        },
        validateOnBlur: false,
    });

    useEffect(() => {
        setRegistrationError("");
    }, []);

    return (
        <div className="bg-pattern" style={{ height: "100vh" }}>
            <div className="bg-overlay"></div>
            <NavBar />
            <div className="account-pages pt-5 margin-custom">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={6} md={8} xl={6}>
                            <Card className='mt-5'>
                                <CardBody className="p-4">
                                    <h4 className="font-size-18 text-muted text-center mt-2">Create Account</h4>
                                    <br />
                                    <Form className="form-horizontal" onSubmit={validation.handleSubmit}>
                                        {successMessage && <Alert color="success">{successMessage}</Alert>}
                                        {registrationError && <Alert color="danger">{registrationError}</Alert>}
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Sponsor ID</Label>
                                                    <Input
                                                        name="sponsorId"
                                                        type="text"
                                                        placeholder="Enter Sponsor ID"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.sponsorId}
                                                        invalid={validation.touched.sponsorId && !!validation.errors.sponsorId}
                                                    />
                                                    {validation.touched.sponsorId && validation.errors.sponsorId && (
                                                        <FormFeedback>{validation.errors.sponsorId}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Sponsor Name</Label>
                                                    <Input
                                                        name="sponsorName"
                                                        type="text"
                                                        placeholder="Enter Sponsor Name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.sponsorName}
                                                        invalid={validation.touched.sponsorName && !!validation.errors.sponsorName}
                                                    />
                                                    {validation.touched.sponsorName && validation.errors.sponsorName && (
                                                        <FormFeedback>{validation.errors.sponsorName}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Choose Position</Label>
                                                    <Input
                                                        type="select"
                                                        name="position"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.position}
                                                        invalid={validation.touched.position && !!validation.errors.position}
                                                    >
                                                        <option value="">Select Position</option>
                                                        <option value="left">Left</option>
                                                        <option value="right">Right</option>
                                                    </Input>
                                                    {validation.touched.position && validation.errors.position && (
                                                        <FormFeedback>{validation.errors.position}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">First Name</Label>
                                                    <Input
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="Enter First Name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.firstName}
                                                        invalid={validation.touched.firstName && !!validation.errors.firstName}
                                                    />
                                                    {validation.touched.firstName && validation.errors.firstName && (
                                                        <FormFeedback>{validation.errors.firstName}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Last Name</Label>
                                                    <Input
                                                        name="lastName"
                                                        type="text"
                                                        placeholder="Enter Last Name"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.lastName}
                                                        invalid={validation.touched.lastName && !!validation.errors.lastName}
                                                    />
                                                    {validation.touched.lastName && validation.errors.lastName && (
                                                        <FormFeedback>{validation.errors.lastName}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Email Address</Label>
                                                    <Input
                                                        name="email"
                                                        type="email"
                                                        placeholder="Enter Email Address"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email}
                                                        invalid={validation.touched.email && !!validation.errors.email}
                                                    />
                                                    {validation.touched.email && validation.errors.email && (
                                                        <FormFeedback>{validation.errors.email}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Gender</Label>
                                                    <Input
                                                        type="select"
                                                        name="gender"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.gender}
                                                        invalid={validation.touched.gender && !!validation.errors.gender}
                                                    >
                                                        <option value="">Select Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="other">Other</option>
                                                    </Input>
                                                    {validation.touched.gender && validation.errors.gender && (
                                                        <FormFeedback>{validation.errors.gender}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Country</Label>
                                                    <Input
                                                        name="country"
                                                        type="text"
                                                        placeholder="Enter Country"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.country}
                                                        invalid={validation.touched.country && !!validation.errors.country}
                                                    />
                                                    {validation.touched.country && validation.errors.country && (
                                                        <FormFeedback>{validation.errors.country}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Mobile Number</Label>
                                                    <Input
                                                        name="mobileNumber"
                                                        type="text"
                                                        placeholder="Enter Mobile Number"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.mobileNumber}
                                                        invalid={validation.touched.mobileNumber && !!validation.errors.mobileNumber}
                                                    />
                                                    {validation.touched.mobileNumber && validation.errors.mobileNumber && (
                                                        <FormFeedback>{validation.errors.mobileNumber}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-4">
                                                    <Label className="form-label">Password</Label>
                                                    <Input
                                                        name="password"
                                                        type="password"
                                                        placeholder="Enter Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password}
                                                        invalid={validation.touched.password && !!validation.errors.password}
                                                    />
                                                    {validation.touched.password && validation.errors.password && (
                                                        <FormFeedback>{validation.errors.password}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="form-check mb-4">
                                            <Input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="terms"
                                                onChange={() => setAgreedToTerms(!agreedToTerms)}
                                                checked={agreedToTerms}
                                            />
                                            <Label className="form-check-label" htmlFor="terms">
                                                I agree to the <Link to="/terms">terms and conditions</Link>
                                            </Label>
                                        </div>

                                        <div className="mb-3">
                                            <button type="submit" className="btn btn-primary w-100" disabled={!agreedToTerms}>
                                                Register
                                            </button>
                                        </div>
                                        <p className="mb-0 text-muted text-center">
                                            Already have an account? <Link to="/login" className="text-primary">Login</Link>
                                        </p>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Register;
