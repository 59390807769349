import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";

const LeftUsers = () => {
  document.title = "Teams | Human rights CWA";

  const [leftUsers, setLeftUsers] = useState([]);

  useEffect(() => {
    // Fetch the package history when the component mounts
    const fetchPackage = async () => {
      try {
        // Retrieve the token from localStorage
        const token = localStorage.getItem('token');
  
        // Make the API request with the Authorization header
        const response = await axios.get('https://mlm-backend-9fnq.onrender.com/api/user/get-teams', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        // Check for a successful response
        if (response?.success) {
          // Extract and flatten the nested referrals array
          const flattenReferrals = (referrals) => {
            let allReferrals = [];
            referrals.forEach(referral => {
              allReferrals.push(referral);
              if (referral?.referrals?.length > 0) {
                allReferrals = allReferrals.concat(flattenReferrals(referral.referrals));
              }
            });
            return allReferrals;
          };
  
          const allReferrals = flattenReferrals(response.referrals);
  
          // Filter users with position "left"
          const usersWithPositionLeft = allReferrals.filter(user => user.position === "left");
          
          setLeftUsers(usersWithPositionLeft);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
  
    fetchPackage();
  }, []);
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Teams" breadcrumbItem="Left Users" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">Left Position Users</h4>
                  <p className="card-title-desc">
                    Below are the users with the position "Left".
                  </p>

                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Country</th>
                          <th>Mobile Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leftUsers.length > 0 ? (
                          leftUsers.map((user, index) => (
                            <tr key={user._id}>
                              <th scope="row">{index + 1}</th>
                              <td>{user?.firstName}</td>
                              <td>{user.lastName}</td>
                              <td>{user.email}</td>
                              <td>{user.country}</td>
                              <td>{user.mobileNumber}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">No users with position "Left"</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LeftUsers;
