import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardBody, Col, Row, Container, Alert, FormGroup, Input, Button } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FormWithdrawRequest = () => {
  document.title = "Withdraw | Human rights CWA";

  const [formData, setFormData] = useState({
    transactionPassword: "",
    amount: "", // User enters amount
  });

  const [commission, setCommission] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [isUserActive, setIsUserActive] = useState(false); // Track user status

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch user profile on mount
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get("https://mlm-backend-9fnq.onrender.com/api/user/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { commission, status } = response;

        setCommission(commission); // Set user commission
        if (status === "active") {
          setIsUserActive(true); // Set user as active if status is Active
          setErrorMessage("");
        } else {
          setIsUserActive(false); // Set user as inactive if status is not Active
          setErrorMessage("You are not an active user. You cannot request a withdrawal. Buy plan first to request..");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
        setErrorMessage("Error fetching user profile.");
      }
    };

    fetchUserProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleVerifyPassword = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://mlm-backend-9fnq.onrender.com/api/user/verify-transaction-password",
        {
          transactionPassword: formData.transactionPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.success) {
        setIsPasswordVerified(true);
        setErrorMessage("");
      } else {
        setErrorMessage(response.message);
        setIsPasswordVerified(false);
      }
    } catch (error) {
      console.error("Error verifying transaction password:", error);
      setErrorMessage("Incorrect transaction password");
      setIsPasswordVerified(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.transactionPassword || !formData.amount) {
      setErrorMessage("Both transaction password and amount are required");
      return;
    }

    if (!isPasswordVerified) {
      setErrorMessage("Please verify the transaction password first.");
      return;
    }

    if (parseFloat(formData.amount) > commission) {
      setErrorMessage("The amount cannot be more than your commission balance.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://mlm-backend-9fnq.onrender.com/api/user/add-withdraw-request",
        {
          transactionPassword: formData.transactionPassword,
          amount: formData.amount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setSuccessMessage(response.message);
      setErrorMessage("");
    } catch (error) {
      console.error("Error creating withdraw request:", error);
      setSuccessMessage("");
      setErrorMessage("Error creating withdraw request");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Withdraw" breadcrumbItem="Withdraw Request" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="mb-4">
                    <h5>Donation: {commission}</h5>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <label htmlFor="transactionPassword" className="col-md-2 col-form-label">
                        Transaction Password
                      </label>
                      <div className="col-md-10">
                        <Input
                          type="text"
                          id="transactionPassword"
                          name="transactionPassword"
                          value={formData.transactionPassword}
                          onChange={handleChange}
                          disabled={!isUserActive} // Disable if the user is not active
                        />
                      </div>
                    </Row>
                    <Button
                      color="primary"
                      onClick={handleVerifyPassword}
                      disabled={!isUserActive || isPasswordVerified}
                    >
                      {isPasswordVerified ? "Verified" : "Verify Password"}
                    </Button>
                    <Row className="mb-3 mt-3">
                      <label htmlFor="amount" className="col-md-2 col-form-label">
                        Amount
                      </label>
                      <div className="col-md-10">
                        <FormGroup>
                          <Input
                            type="number"
                            id="amount"
                            name="amount"
                            value={formData.amount}
                            onChange={handleChange}
                            disabled={!isUserActive || !isPasswordVerified} // Disable if not active or password not verified
                            min="1"
                            max={commission}
                          />
                        </FormGroup>
                      </div>
                    </Row>

                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                    <Button
                      type="submit"
                      color="primary"
                      disabled={!isPasswordVerified || !isUserActive} // Disable if the user is not active
                    >
                      Submit
                    </Button>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormWithdrawRequest;
